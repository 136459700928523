<template>
    <div :style="{'--themecolor':themecolors}">
        <van-loading :color="themecolors" :text-color="themecolors" v-if="isHttpData"/>
        <div v-else style="height:100%">
            <div class="Addendum_box" id="scroll">
                <div class="Addendum_open" @click="openChange" v-if="!openShow">{{button_title}}</div>
                <div style="min-height:95%;padding: 0.2rem 0.2rem 0 0.2rem;">
                    <div class="Addendum_box_top" v-if="openShow">
                        <div class="Addendum_box_center" :style="{padding:fileListShow?'0.2rem 0.1rem;':'0.3rem 0.1rem;'}">
                            <div class="put_away" v-if="fileList.length==0" @click="openShow=false">收起</div>
                            <div class="file_upload_box" v-if="fileListShow">
                                <div class="file_upload" v-for="(item,index) in fileList" :key="index">
                                     <div class="conbox">
                                         <div class="conleft"> <img :src='"@/assets/addendumicon/"+item.filetype+".svg"'></div>
                                         <div class="conright">
                                                <p class="file_title">{{item.file_title}}</p>
                                                <div class="bottomBox">
                                                    <div class="rightBot">
                                                        <div class="rigbto">
                                                            <span style="color: #888888;min-width:.7rem;">{{(item.size/1024/1000).toFixed(2)}}MB</span>
                                                            <span :style="{color:themecolors,'margin-left':'8px'}">{{item.format}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                         </div>
                                         <van-icon name="close"  class="close_icon" @click="DeleteFile(index)"/>
                                    </div>
                                </div>
                            </div>
                            <div class="Addendum_box_text text_click" v-if="speedData==0&&!fileListShow" v-html="introduction"></div>
                            <div class="Addendum_box_speed" v-if="speedData!=0&&!fileListShow">完成度{{speedData}}%</div>
                            <van-slider v-model="speedData" active-color="rgb(0 231 199)" v-if="speedData!=0&&!fileListShow">
                                <template #button>
                                    <div class="custom-button"></div>
                                </template>
                            </van-slider>
                            <div class="Addendum_btn">
                                <van-button round type="info" color="#00ccb0" @click="UploadData" :disabled="uploadshow">选择文件</van-button> 
                                <van-button round type="info" color="#00ccb0"  @click="UploadServer" class="upload_data" :disabled="uploadshow">上传</van-button>
                                <input type="file" ref="filebox" id="file-box" multiple class="input-upload" @change="clickFile($event)"   style="display: none;"/>
                            </div>
                            
                        </div>
                    </div>
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了"  @load="onLoad" v-if="filelistData.length>0">
                        <van-cell v-for="(item,index) in filelistData" :key="index"  >
                            <div class="conbox">
                                    <div class="conleft"> <img :src="item.cover_url"></div>
                                    <div class="conright">
                                        <p class="file_title">{{item.file_title}}</p>
                                        <div class="bottomBox">
                                            <div class="rightBot">
                                                <div class="time">{{item.created_at}}</div>
                                                <div class="rigbto">
                                                    <span style="color: #888888;min-width:.7rem;">{{item.size}}MB</span>
                                                    <span :style="{color:themecolors,'margin-left':'8px'}">{{item.format}}</span>
                                                </div>
                                            </div>
                                            <div class="leftBot" @click="fileLink(item,2)">
                                                <!-- <a download="w3logo" :href="item.file_link_url"> -->
                                                <a download="w3logo" >
                                                    <span>预览</span>
                                                </a>
                                            </div>
                                            <div class="leftBot" @click="DeleteUploadFile(item,index)">
                                                <!-- <a download="w3logo" :href="item.file_link_url"> -->
                                                <a download="w3logo">
                                                    <span>删除</span>
                                                </a>
                                                
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </van-cell>
                    </van-list>
                    <div class="empty_data" v-if="filelistData.length==0">
                        <van-empty :image="require('@/assets/attend/none-img.svg')" />
                        <div class="date_line-title">
                            <p>暂无文件</p>
                        </div>
                    </div>
                </div>
                <div class="iframe_box" v-if="previewUrl">
                    <div class="cross" @click="crossShow" :style="{background:themecolor}"><van-icon name="cross" /></div>
                    <div class="loading_box" v-if="iframe_Loading && previewUrl">
                        <van-loading :color="themecolors" :text-color="themecolors"/>
                    </div>
                    <iframe id="ifra" :src="previewUrl" width="100%" height="100%" border="0" style="border: none;" />
                </div>
            
                <Support ></Support>
            
            </div>
            <sideBar v-show="!previewUrl"></sideBar>
        </div>
        
    </div>
</template>

<script>
import { mapState } from 'vuex'
import sideBar from '@/components/sidebar';
import Support from '@/components/support';
import Loading from "@/components/loading/loading";
export default {
    components:{
        sideBar,
        Support,
        Loading
    },
    data(){
        return{
            button_title:'',//按钮文案
            introduction:'',//展示页面文案
            isHttpData: true,//页面加载动画
            site_id:'',
            fileList:[],//暂存的文件列表
            qiniutoken:'',//七牛token
            keywords:'',//检索数据
            page:1,//征文页数
            page_num:10,//征文每页数量
            number:0,//已上传成功的个数
            filelistData:[],//已上传的文件
            loading: false,//下拉加载动画
            finished: false,//下拉加载是否是最后
            previewUrl:"",//本地预览链接
            iframe_Loading:true,//本地预览加载动画
            speedData:0,//上传完成度
            uploadshow:false,//防抖上传按钮
            deleteShow:false,//防抖删除按钮
            fileListShow:false,//是否显示列表
            openShow:false,//是否显示上传窗口
        }
    },
    watch:{
        previewUrl(val){
            if(val){
                let that=this
                this.$nextTick(()=>{
                     const iframe = document.querySelector('#ifra')
                    if (iframe.attachEvent) {
                        iframe.attachEvent('onload', function () {
                            that.iframe_Loading=false
                        })
                    } else {
                        iframe.onload = function () {
                            that.iframe_Loading=false
                        }
                    }
                })
            } 
        },
    },
    computed: {
        ...mapState(['themecolors','themecolor']),
    },
    created(){
        this.site_id = this.$route.query.site_id;
        this.GetToken()
        
    },
    mounted(){
        this.front_end_call_for_papers()
        this.get_front_end_user_file_list() 
    },
    methods:{
        //显示上传窗口
        openChange(){
            this.openShow=true
            // this.animation=true
        },
        //下拉加载数据
        onLoad(){
            this.page++
            this.get_front_end_user_file_list()
        },
        // 本地预览
        fileLink(item){
                var file = item.file_url;
                var xurl = "https://view.xdocin.com/view?src=";
                xurl += encodeURIComponent(file);
                this.previewUrl=xurl;
        },
        // 关闭本地预览
        crossShow(){
            this.previewUrl=""
            this.iframe_Loading=true
        },
        // 删除上传预览列表的文件
        DeleteFile(index){
            this.fileList.splice(index,1)
            if( this.fileList.length==0){
                this.fileListShow=false
                this.$refs.filebox.value=""
            }
        },
        // 删除已上传的文件
        DeleteUploadFile(item,index){
             if(this.deleteShow){
                return
             }
             this.deleteShow=true
             this.$store.dispatch("del_front_end_user_file", {
                site_id: this.site_id,
                id:item.id,
            }).then(res=>{
                if(res.data.code==200){
                    this.filelistData.splice(index,1)
                    this.deleteShow=false
                    if(this.filelistData==0){
                        this.openShow=true
                        // this.animation=true
                    }
                }
            });
        },  
        front_end_call_for_papers(){
            this.$store.dispatch("front_end_call_for_papers", {
                site_id: this.site_id,
            }).then(res=>{
                if(res.data.code==200){
                    this.button_title=res.data.data.button_title
                    this.introduction=res.data.data.introduction
                }
            });
        },
        // 获取已上传的文件
        async get_front_end_user_file_list(){
            const res= await this.$store.dispatch("get_front_end_user_file_list", {
                site_id: this.site_id,
                keywords:this.keywords,
                page:this.page,
                page_num:this.page_num,
            });
            if(res.data.code==200){
                if (Array.isArray(res.data.data.data)) {
                    this.filelistData=[...this.filelistData,...res.data.data.data]
                } else {
                    this.filelistData=[...this.filelistData]
                }
                if(this.filelistData.length==0){
                    this.openShow=true
                    // this.animation=false
                }
                this.loading = false;
                this.isHttpData=false
                if(res.data.data.current_page>=res.data.data.last_page){
                    this.finished = true;
                } 
            } else {
                this.$toast(res.data.message)
            }
        },
        // 上传完成后添加的数据
        AppList(){
            this.$store.dispatch("get_front_end_user_file_list", {
                site_id: this.site_id,
                keywords:this.keywords,
                page:1,
                page_num:this.page*this.page_num,
            }).then(res=>{
                this.filelistData=res.data.data.data
                this.number=0
                this.uploadshow=false
                 this.$refs.filebox.value=""
            });
        },
        // 上传按钮
        async UploadServer(){
            if(this.fileList.length==0){
                this.$toast('请添加需要上传的文件!')
            }else{
                this.fileListShow=false
                this.uploadshow=true
                this.fileList.forEach((item,index)=>{
                    this.QiniuUpload(item.file,index)
                })
            }
           
        },
        // 添加余上传文件
        UploadData(){
          this.$refs.filebox.click();
        },
        // 添加想要上传的文件
        async clickFile(e){
             for(let i = 0;i< e.target.files.length;i++ ){
                let size=(e.target.files[i].size/1024/1000).toFixed(2)
                if(size>500){
                    this.$toast(`单个文件最多不能超过500MB`)
                    e = ''
                    return
                }
            }
            if ( e.target.files.length > 10) {
                this.$toast(`最多可同时上传10个文件，您选择了${e.target.files.length}个`)
                e = ''
                return
            }else{
                this.fileListShow=true
                this.speedData=0
                for(let i = 0;i< e.target.files.length;i++ ){
                    let format=e.target.files[i].name.split(".").pop()
                     let obj={
                        filetype:'',
                        file:e.target.files[i],
                        file_title:e.target.files[i].name,
                        file_url:'',
                        size:e.target.files[i].size,
                        format:format
                    }
                         switch(format){
                            case "png":
                                obj.filetype='img'
                                break
                            case "jpg":
                                obj.filetype='img'
                                break
                            case "jpeg":
                                obj.filetype='img'
                                break
                            case "svg":
                                obj.filetype='img'
                                break
                            case "webp":
                                obj.filetype='img'
                                break
                            case "xlsx":
                                obj.filetype='xlsx'
                                break
                            case "mp3":
                                obj.filetype='mp3'
                                break
                            case "mp4":
                                obj.filetype='mp4'
                                break
                            case "txt":
                                obj.filetype='txt'
                                break
                            case "word":
                                obj.filetype='word'
                                break
                            case "zip":
                                obj.filetype='zip'
                                break
                            case "pdf":
                                obj.filetype='pdf'
                                break
                            case "webp":
                                obj.filetype='webp'
                                break
                            default: obj.filetype='null'
                        }
                    this.fileList.push(obj)
                }
            }
            
        },
        //上传到七牛上
        QiniuUpload(file,index){
            let uptoken=this.qiniutoken
            let key = new Date().getTime() + Math.random(1000) + file.name;   //这是上船后返回的文件名，这里为了避免重复，加上了时间戳和随机数
            var config = {
                useCdnDomain: true,        //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
                domain: "http://images.qingtv.com", //配置好的七牛云域名  如   https://cdn.qniyun.com/
                chunkSize: 100,     //每个分片的大小，单位mb，默认值3
                forceDirect: true    //直传还是断点续传方式，true为直传
            };
            var putExtra = {
                fname: file.name,    //文件原始文件名
                params: {},
                mimeType: [] || null
            };
            var observable = this.$qiniu.upload(file, key, uptoken, putExtra, config);
            observable.subscribe({
                next: (result) => {
                    //主要用来展示进度
                    if( this.fileList.length==1){
                        this.speedData=Math.trunc(result.total.percent)
                    }
                },
                error: (err) => {
                    //上传错误后触发
                    console.log(err);
                    // reject(err)
                },
                complete: (result) => {
                    //上传成功后触发。包含文件地址。
                    this.fileList[index].file_url="https://images.qingtv.com/"+result.key+""
                    delete this.fileList[index].file
                    this.number++
                    this.speedData= Math.round(this.number/this.fileList.length*100)
                    if(this.number==this.fileList.length){
                         this.$store.dispatch("add_front_end_user_file", {
                            site_id: this.site_id,
                            file_content: JSON.stringify(this.fileList),
                        }).then(res =>{
                            if(res.data.code==200){
                                this.fileList=[]
                                this.AppList()
                            }
                        });
                    }
                    resolve(result)
                },
            });
        },
        // 七牛token
        GetToken(){
              this.$store.dispatch('get_verify_space_size',{
                 site_id:this.site_id,
                 storage_space:'1',
                 size:'86094'
            }).then(res=>{
               if(res.data.code=200){
                    this.qiniutoken=res.data.data.upload_token
               }
            })
        }
    }
}
</script>

<style lang='less' scoped>
@Color:var(--themecolor);
@rgbathemecolor:var(--rgbathemecolor);
/deep/.van-loading{
    position: absolute;
    top: 50%;
    left:50%;
    transform:translateX(-50%);
}
.empty_data{
     /deep/.van-empty{
        width: 100%;
        padding-bottom: 0;
        .van-empty__image{
            width: 3rem;
            height: 3rem;
        }
     }
     .date_line-title{
        text-align: center;
        color: #999;
     }
}


.flex{
    display: flex;
}
.between{
    justify-content: space-between;
}
.Addendum_box{
    font-size: .14rem;
    // padding: .2rem .2rem 0 .2rem; 
    background: #fff;
    height: 100%;
    overflow: auto;
    .Addendum_open{
        padding: 0 .1rem;
        width: 100%;
        height: .4rem;
        line-height: .4rem;
        background: @Color;
        text-align: center;
        // border-radius: .3rem;
        float: right;
        font-size: .14rem;
        color: #fff;
    }
    //  .Addendum_box_animation{
    //         animation: slide-down .3s ease-in;
    //         // transition:  .5s ease-in;
    //         transform-origin:100% 0;
    //     }

    //     @keyframes slide-down{
    //         0%{transform: scale(1,0);}
    //         // 25%{transform: scale(0.25,0.25);}
    //         // 50%{transform: scale(0.5,0.5);}
    //         // 75%{transform: scale(0.75,0.75);}
    //         100%{transform: scale(1,1);}
    //     }
    .Addendum_box_top{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height:1.4rem;
        border-radius: .1rem;
        overflow: hidden;
        border:.02rem #999 dashed;
       
        .Addendum_box_center{
            position: relative;
            padding:0.3rem 0.1rem;
            width: 100%;
            min-height: 1.34rem;
            margin:.02rem;
            background: rgba(0, 204, 176, 0.3);
            border-radius: .1rem;
            .put_away{
                color: #999;
                position: absolute;
                top: 0.1rem;
                right: 0.1rem;
            }
            .Addendum_btn{
                text-align: center;
            }
            .Addendum_box_text{
                width: 100%;
                // text-align: center;
                overflow-y: scroll;
                max-height: 1.5rem;
                min-height: 0.64rem;
                color: #000;
                margin-bottom: 0.15rem;
            }
            .Addendum_box_speed{
                text-align: center;
                height: 0.3rem;
                margin-bottom: 0.15rem;
                line-height: 0.3rem;
                font-size: 0.16rem;
            }
            .text_click{
                margin-bottom: 0.2rem;
                min-height: 0.5rem;
                padding: 0 0.1rem;
            }
            .van-slider{
                margin: 0 auto;
                width: 80%;
                height: 0.1rem;
                margin-bottom: 0.15rem;
            }
            .upload_data{
                margin-left: .2rem;
            }
            .van-button{
                font-size: .14rem;
                width: .9rem;
                height: 0.35rem;
            }
        }
    }
    .file_upload_box{
        overflow-y: scroll;
        max-height: 1.5rem;
        min-height: 0.64rem;
        font-size: 0.14rem;
        margin-bottom: 0.15rem;
        padding:0 0.1rem;
    }
    .file_upload{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.1rem;
         background: #fff;
        &:last-child{
            margin-bottom: 0;
        }
        .close_icon{
            color: #999;
            font-size: .18rem;
            margin-right: 0.1rem;
        }
        .file_title{
            width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
         .conbox{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
             overflow: hidden;
            .conleft{
                width: .6rem;
                height: .6rem;
                padding: 10px;
                display: flex;
                align-items: center; /*垂直居中*/
                justify-content: center; /*水平居中*/
                background:#FAFAFA;
                img{
                    width: .3rem;
                }
            }
            .conright{
                flex: 1;
                padding:.05rem .1rem;
                .file_title{
                    margin-bottom: 5px;
                }
                p{
                    font-size: .14rem;
                    color: #000000;
                    display:-webkit-box;
                    overflow: hidden;
                    word-break:break-all;
                    width: 100%;
                   -webkit-box-orient: vertical;
                   -webkit-line-clamp: 2;
                   line-height: .2rem;
                   white-space: break-spaces;
                }
                .bottomBox{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: .1rem;
                .rightBot{
                    width: 100%;
                    height: 100%;
                    width: 70%;
                    line-height: .14rem;
                    span{
                        min-width: .3rem;
                        display: inline-block;
                    }
                    .time{
                        color: #888888;
                    }
                    img{
                        width: .12rem;
                    }
                }
                .leftBot{
                    width: .5rem;
                    height: .3rem;
                    border:1px solid @Color;
                    border-radius: 3px;
                    &:last-child{
                        margin-left: .1rem;
                    }
                    span{
                        text-align: center;
                        line-height: .3rem;
                        color: @Color;
                        display: block;
                        font-weight: bold;
                    }                            
                }
                }
            }
         }
    }
    .van-cell{
        padding:0;
        .van-cell__value{
            width: 100%;
            margin: 0 auto;
            display: flex;
        }
         .conbox{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #EBEDF0;
            padding: .1rem;
            .conleft{
                width: .6rem;
                height: .6rem;
                padding: 10px;
                display: flex;
                align-items: center; /*垂直居中*/
                justify-content: center; /*水平居中*/
                background:#FAFAFA;
                img{
                    width: .3rem;
                }
            }
            .conright{
                flex: 1;
                padding:.05rem .1rem;
                .file_title{
                    margin-bottom: 5px;
                }
                p{
                    font-size: .14rem;
                    color: #000000;
                    display:-webkit-box;
                    overflow: hidden;
                    word-break:break-all;
                    width: 100%;
                   -webkit-box-orient: vertical;
                   -webkit-line-clamp: 2;
                   line-height: .2rem;
                }
                .bottomBox{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: .1rem;
                .rightBot{
                    width: 100%;
                    height: 100%;
                    width: 70%;
                    line-height: .14rem;
                    span{
                        min-width: .3rem;
                        display: inline-block;
                    }
                    .time{
                        color: #888888;
                    }
                    img{
                        width: .12rem;
                    }
                }
                .leftBot{
                    width: .5rem;
                    height: .3rem;
                    border:1px solid @Color;
                    border-radius: 3px;
                    &:last-child{
                        margin-left: .1rem;
                    }
                    span{
                        text-align: center;
                        line-height: .3rem;
                        color: @Color;
                        display: block;
                        font-weight: bold;
                    }                            
                }
                }
            }
         }
    }
   
    .file_box{
        display: flex;
        align-items: center;
        .file_box_name{
            max-width: 100%;
            overflow: hidden;
            margin: 0 .1rem;
            flex: 1;
        }
        img{
            width: 0.3rem;
        }
    }
}
  .iframe_box{
        background: #000;
         height:100%;
         width:100%;
         position: absolute;
         top: 0;
         left: 0;
        /deep/.van-loading{
            position: absolute;
            top:50%;
            left:50%;
            transform:translateX(-50%);
        }
         .cross{
            position: fixed;
            right: 0.15rem;
            top: 0.1rem;
            font-size: .2rem;
            background: rgba(133, 133, 133, 0.3);
            color: #fff;
            width: 0.35rem;
            height: 0.35rem;
            text-align: center;
            line-height: .35rem;
            border-radius:.35rem ;
         }
         .loading_box{
            width: 100%;
            height: 100%;
            background: #000;
         }
    }
</style>